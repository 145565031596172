export let API_CONFIG = {
  baseUrl: 'https://services-upgrade-dev.pno-isolutions.nl', //https://services-development.innonation.nl // 
  fileUrl: 'https://services-upgrade-dev.pno-isolutions.nl', //https://services-development.innonation.nl // 
  appDefaultToken: 'test12345@@@@@@@@@', //test12345@@@@@@@@@
  appId: '5bacdd9fb9bf1c120bef1088',
  appName: 'Develop',
  currentHostName: window.location.hostname,
  debugMode: false,
  webSocketConnect: 'wss://services-upgrade-dev.pno-isolutions.nl/ws/chat',
  logoFileName: 'logo.jpg',
  theme: 'flat', //'flat',
  withDivisionMain: 'APP_CONTAINER_BORDER', //'',
  JWT: {
      algorithm: 'HS256',
      secret: 'AAAAAAAAAAAAAA'
  },
  progressType: 'type2',
  defaultPrimary: '#b2a944',
  enableThemeMenu: true,
  publisherType:'PUBLISHER_TYPE'
}
try {
  let config = require(`../../../static/appSetup/${API_CONFIG.currentHostName}/config.json`)
  if (config) {
      config.logoFileName = `appSetup/${API_CONFIG.currentHostName}/${config.logoFileName || API_CONFIG.logoFileName}`
      config.favIcon = `static/appSetup/${API_CONFIG.currentHostName}/${config.favIcon}` || 'static/favicon.ico'
      API_CONFIG = {...API_CONFIG,...config}
  }
} catch(e) {
  console.log('INFO. There was an issue with app config',e)
}
export default API_CONFIG